.manualPage {
    background-image: url('./../../img/grafika_PAI_ostateczny_projekt.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    width: calc(100vw - 74px);
    height: calc(100vh - 56px);
}

@media only screen and (max-width: 600px) {
    .manualPage {
        width: calc(100vw - 57px);
        height: calc(100vh - 57px);
    }
}

@media only screen and (min-width: 600px) {
    .manualPage {
        width: calc(100vw - 74px);
        height: calc(100vh - 64px);
    }
}
