@import url('~leaflet/dist/leaflet.css');

.leaflet-container {
    background: #fafafa;
    outline: 0;
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 30px;
    height: 30px;
}

.leaflet-retina .leaflet-control-layers-toggle {
    background-image: url(./img/layersSwitcher.png);
    background-size: 16px 16px;
}

.leaflet-control-layers label {
    display: flex;
}

.leaflet-popup-content-wrapper {
    padding: 1px;
    text-align: left;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.7);
}

/*.leaflet-popup-content-wrapper, .leaflet-popup-tip {*/
/*    background: #ffffffd1;*/
/*    color: #333;*/
/*    box-shadow: 0 3px 14px rgba(0,0,0,0.4);*/
/*}*/

.leaflet-div-icon {
    background: #fff;
    border: 1px solid #666;
    border-radius: 50%;
}

.material-icons.md-10 {
    font-size: 14px;
    padding-top: 4px;
    vertical-align: central;
}

.material-icons.button {
    margin: 1px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius:4px;
    border:0.01rem solid #241d13;
}

.material-icons.md-12 {
    /*color: #ffffff;*/
    padding-bottom:  2px;
    font-size: 13px;

    /*padding-left: 0.41em;*/
}

.legend-circle {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    margin-left: -5px;
    margin-top: -5px;
    display: inline-block;
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
